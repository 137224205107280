import React, { useEffect, useState } from 'react';
import fileNopreview from '../Assests/images/file_nopreview.png';
import linkNopreview from '../Assests/images/link_nopreview.png';
import VideoJS from './VideoPlayer';
import WavesurferComponent from './WavesurferComponent';
import FileNotFoundComponent from './FileNotFound';

function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

const AttachmentsItem = ({ attachments, viewVideoIcon, handlePlayerReady, viewAudioIcon, viewIcon, showImages, showIframe }) => {
    const [jsonData, setJsonData] = useState(null);
    const [thumbnailUrl, setthumbnailUrl] = useState(null);
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
    };

    /* const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent || '') ||
        /iPad|iPhone|iPod/i.test(navigator.userAgent || ''); */

    useEffect(() => {
        const fetchData = async (item) => {
            try {
                const response = await fetch(item.sources[0].url);

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const text = await response.text();

                if (text.trim() === '') {
                    // Handle empty response here, e.g., set an appropriate default value for jsonData
                    setJsonData('');
                } else {
                    const data = JSON.parse(text);
                    setJsonData(data);
                    setthumbnailUrl(data?.links?.thumbnail?.[0]?.href);
                }
            } catch (error) {
                console.error('Error fetching JSON data:', error);
            }
        };

        attachments
            .filter((item) => item?.mediaType === 4)
            .forEach((item) => {
                fetchData(item);
            });
    }, [attachments]);

    const handleImageError = () => {
        // Handle the error, e.g., by displaying a default image
        const imageElement = document.getElementById("thumbnailImage");
        if (imageElement) {
            imageElement.src = linkNopreview;
            imageElement.classList.add("contain-img");
            setthumbnailUrl(linkNopreview);

        }
    };

    return (
        <>
            {attachments && attachments.length > 0 &&
                attachments?.map((item) => {
                    const mediaType = item?.mediaType;
                    videoJsOptions.sources = [{
                        src: item?.sources[0].url,
                        type: item?.sources[0].mimeType
                        // type: 'video/mp4', // Adjust the type based on your video format
                    }];
                    if (item?.sources && item?.sources.length > 0) {

                        const metadata = JSON.parse(item.sources[0].metadata);
                        const size = metadata.size;
                        const formattedSize = formatFileSize(size);

                        switch (mediaType) {
                            case 0: // Image
                                return (
                                    <div className="onlyImg" key={item.id}>
                                        {item.sources[0].url ? (
                                            <img className="cover-img" src={item.sources[0].url} alt="Media" />
                                        ) : (
                                            <FileNotFoundComponent />
                                        )}
                                        {viewIcon}
                                    </div>
                                );
                            case 1: // Video
                                return (
                                    <div key={item.id} className="video-container">
                                        <VideoJS id={`video-${item.id}`} viewVideoIcon={viewVideoIcon}
                                            viewIcon={viewIcon} className="cover-img" options={videoJsOptions} onReady={handlePlayerReady} />
                                    </div>
                                );
                            case 2: // Audio
                                return (
                                    <div key={item.id}>
                                        {item.sources[0].url ? (
                                            // isSafari && item.sources[0].mimeType === 'audio/ogg' ? (
                                            //     <FileNotFoundComponent />
                                            // ) : (
                                                <WavesurferComponent canvasHeight="32" viewAudioIcon={viewAudioIcon} audioUrl={item.sources[0].url} />
                                            // )
                                        ) : (
                                            <FileNotFoundComponent />
                                        )}
                                    </div>
                                );
                            case 3: // Document (e.g., PDF, Word)
                                return (
                                    <div key={item.id}>
                                        <div className="link-box-top">
                                            <div className="link-box">
                                                <div className="link-box-content document-box">
                                                    <div className="no-preview-img">
                                                        {showImages && (
                                                            <>
                                                                <img
                                                                    src={item.sources[1]?.url || fileNopreview}
                                                                    alt="File no preview"
                                                                    className={item.sources[1]?.url ? 'cover-img' : 'contain-img'}
                                                                />
                                                                {viewIcon}
                                                            </>
                                                        )}
                                                        {showIframe && (
                                                            <iframe src={item.sources[0]?.viewerUrl || 'URL_TO_FALLBACK_DOCUMENT'} title={item.fileName}></iframe>
                                                        )}
                                                    </div>
                                                    <div className="file-name">
                                                        <a href={item.sources[0]?.url || 'URL_TO_FALLBACK_DOCUMENT'} target="_blank" rel="noopener noreferrer">
                                                            {item.fileName}
                                                        </a>
                                                        <p className="file-size">
                                                            {formattedSize}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            case 4: // JSON Link
                                if (jsonData) {
                                    const title = jsonData.meta.title;
                                    const embedLink = jsonData?.links?.player?.[0]?.href;
                                    const domName = new URL(jsonData.meta.canonical).hostname.replace(/^www\./, '');

                                    return (
                                        <div key={item.id}>
                                            <div className="link-box-top">
                                                <div className="link-box">
                                                    <div className="link-box-content">
                                                        <div className="no-preview-img">
                                                            {embedLink ? (
                                                                <iframe
                                                                    width="560"
                                                                    height="315"
                                                                    src={embedLink}
                                                                    title={title}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    allowFullScreen
                                                                ></iframe>
                                                            ) : (
                                                                <>
                                                                    {thumbnailUrl && thumbnailUrl !== linkNopreview ? (
                                                                        <>
                                                                            <img id="thumbnailImage" alt="Link preview" onError={handleImageError} src={thumbnailUrl} className='cover-img' />
                                                                            {viewIcon}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <img id="thumbnailImage" alt="Link preview" onError={handleImageError} src={thumbnailUrl} />
                                                                            {viewIcon}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="file-name link-icon-left">
                                                            <p>{domName}</p>
                                                            <a href={jsonData.url} target="_blank" rel="noopener noreferrer">
                                                                {title}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    const itemSourcese = item.sources[0]?.metadata;
                                    const jsonItem = JSON.parse(itemSourcese); // Parse the JSON string into an object
                                    const title = jsonItem?.url;
                                    const thumbnailUrl = linkNopreview;
                                    const domName = '';

                                    return (
                                        <div key={item.id}>
                                            <div className="link-box-top">
                                                <div className="link-box">
                                                    <div className="link-box-content">
                                                        <div className="no-preview-img">
                                                            <img src={thumbnailUrl} alt="Link preview" className="contain-img" />
                                                            {viewIcon}
                                                        </div>
                                                        <div className="file-name ">
                                                            <p>{domName}</p>
                                                            <a href={jsonItem?.url} target="_blank" rel="noopener noreferrer">
                                                                {title}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            default:
                                return null;
                        }
                    } else {
                        return null;
                    }
                })}
        </>
    );
};

export default AttachmentsItem;
