

import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import FileNotFoundComponent from './FileNotFound';
import mediaLoading from '../Assests/images/mediaLoading.png';
import { useTranslation } from 'react-i18next';

function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60); // Ensure seconds are rounded down to an integer

    // Use padStart to ensure seconds are always displayed with 2 digits
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}


const AudioWaveform = ({ viewAudioIcon, audioUrl, canvasHeight }) => {
    const [isMediaProcessing, setIsMediaProcessing] = useState(false);
    // const [isWaveformReady, setIsWaveformReady] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false); // Initialize mute state
    const waveformRef = useRef(null);
    const controlsRef = useRef(null);
    const timelineRef = useRef(null); // Add this line
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0); // Add state to track total duration
    const [volume, setVolume] = useState(1); // Initial volume value
    const [fileNotFound, setFileNotFound] = useState(false);
    const wavesurferRef = useRef(null);
    const { t } = useTranslation();

    const handleVolumeChange = (newVolume) => {
        setVolume(newVolume);
        // Set the volume in Wavesurfer
        wavesurferRef.current.setVolume(newVolume);
        setIsMuted(newVolume === 0);
    };
    const handleMuteClick = () => {
        const newMuteState = !isMuted; // Toggle mute state
        setIsMuted(newMuteState); // Update the mute state in React state
        const newVolume = newMuteState ? 0 : 1; // Set the volume based on the mute state
        setVolume(newVolume); // Update the volume state
        wavesurferRef.current.setVolume(newVolume); // Set the volume in Wavesurfer
    };
    
    // Only use MediaElement backend for Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent || '') ||
        /iPad|iPhone|iPod/i.test(navigator.userAgent || '');

    useEffect(() => {
        // if (!waveformRef.current) {
            // setIsMediaProcessing(true);
            // setIsWaveformReady(false);
            // return; // Do nothing if the container element is not available yet
        // }
        // Check for audio support in the browser
        if (!window.AudioContext && !window.webkitAudioContext) {
            setFileNotFound(true);
            return;
        }
        if(waveformRef.current) {
        const wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            responsive: true,
            height: canvasHeight, // Set the canvas height
            waveColor: "#aab7cb",
            progressColor: "#2962ff",
            cursorColor: "#2962ff",
            barWidth: 4,
            audioRate: 1,
            backend: isSafari ? 'MediaElement' : 'WebAudio', // Use 'MediaElement' for Safari, 'WebAudio' for others
        });
        // wavesurfer.on('loading', () => {
            // setIsMediaProcessing(true);
            // setTotalDuration(wavesurfer.getDuration());
        // });

        // Handle decoding errors
        wavesurfer.on('error', (error) => {
            setFileNotFound(true);
        });

        wavesurfer.on('ready', () => {
            setIsMediaProcessing(false);
            setTotalDuration(wavesurfer.getDuration());
        });

        // Store Wavesurfer instance in ref for later access
        wavesurferRef.current = wavesurfer;

        // Custom controls design
        const playButton = controlsRef.current.querySelector('.play-button');
        playButton.addEventListener('click', () => {
            if (wavesurfer.isPlaying()) {
                wavesurfer.pause();
                setIsPlaying(false); // Update state
            } else {
                wavesurfer.play();
                setIsPlaying(true); // Update state
            }
        });


        //****Custom audio Sound***/
        const volumeSlider = document.getElementById('volume-slider');
        volumeSlider.addEventListener('input', () => {
            const volume = parseFloat(volumeSlider.value);
            setVolume(volume);
            wavesurfer.setVolume(volume);
            setIsMuted(volume === 0);
        });


        // Add timeline interaction
        wavesurfer.on('audioprocess', () => {
            const currentTime = wavesurfer.getCurrentTime();
            setCurrentTime(wavesurfer.getCurrentTime());
            const duration = wavesurfer.getDuration();
            const percentage = (currentTime / duration) * 100;
            timelineRef.current.style.width = `${percentage}%`;
            if (currentTime >= duration) {
                wavesurfer.pause();
                setIsPlaying(false); // Update state
            }
        });

        // Load audio file
        wavesurfer.load(audioUrl)
        .then(() => {
            setIsMediaProcessing(false);
        })
        .catch((error) => {
            setFileNotFound(true);
        });;

        return () => {
            wavesurfer.destroy();
        };
    }
    }, [isSafari, audioUrl, canvasHeight]);



    return <>
        {fileNotFound ? (
            <FileNotFoundComponent />
        ) : (
            <div className="audioPlayer-main">
                { isMediaProcessing ? (
                    <div className="media-processing">
                        <img src={mediaLoading} alt={t("Media is currently processing")} />
                        <h3>{t("Media is currently processing")}</h3>
                        <p>{t("Check back later to access this file")}</p>
                    </div>
                ) : (
                    <>
                <div className="cover-img audio-player" ref={waveformRef} />
                <div className="custom-controls" ref={controlsRef}>
                    <div className="time-play">
                        <button className="play-button">
                            {isPlaying ? <i className="icon icon-pause" /> : <i className="icon icon-play" />} {/* Update button icon */}
                        </button>
                        <div className="current-time">
                            {formatTime(currentTime)} | {formatTime(totalDuration)} {/* Display current time and total duration */}
                        </div>
                        {/* <!-- Volume control --> */}

                    </div>
                    <div className="timeline-main">
                        <div className="timeline">
                            <div className="timeline-progress" ref={timelineRef}></div>
                        </div>
                        <div className="volume-btn">
                            <div className="volume-range">
                                <input type="range" id="volume-slider" min="0" max="1" step="0.01" value={volume} onChange={(e) => handleVolumeChange(e.target.value)} />
                            </div>
                            <button id="mute-button" onClick={(e) => handleMuteClick(e)}  >
                                {isMuted ? <i className="icon icon-mute"></i> : <i className="icon icon-audioOn"></i>}
                            </button>
                        </div>
                        <div className="fullscreen-btn">
                            {viewAudioIcon}
                        </div>
                    </div>
                </div>
                </>
                )}
            </div>
        )}
    </>;
};

export default AudioWaveform;
