import React, { useEffect, useState } from 'react';
import Loading from './Components/LoadingPortfolio';
import AppRoutes from './Routers/Routes';


const App = () => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating an async operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="App">
       {/* <AppRoutes /> */}
      {isLoading ? <Loading /> : <AppRoutes />}
    </div>
  );
};

export default App;
